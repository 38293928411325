import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import authService from '../services/auth-service'

function SignUp({viewportWidth, mobileSize}) {

    //input states
    const [reqPayload, setReqPayload] = useState({
        username: '',
        password: '',
        uid: ''
    })
    const [confPass, setConfPass] = useState('')
    const [currForm, setCurrForm] = useState(0)
    const [authKeyDisplay, setAuthKeyDisplay] = useState('')
    const [expand, setExpand] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showMsg, setShowMsg] = useState(false)
    const [msgText, setMsgText] = useState('Error')
    const [msgUid, setMsgUid] = useState('')

    const handleFirstSubmit = async (e) => {
        e.preventDefault()
        if (reqPayload.password !== confPass) {
            setMsgText("Password confirmation doesn't match")
            setShowMsg(true)
            return
        }
        try{
            setAuthKeyDisplay((await authService.submitNew(reqPayload)).authKey)
            setCurrForm(1)
            setShowMsg(false)
        }catch(err){
            if (err.response.data.uid){
                setMsgText(`UID ${err.response.data.uid} doesn't exist on our database.`)
                setMsgUid(err.response.data.uid)
            }
            else{
                setMsgText(err.response.data.error)
                setMsgUid('')
            }
            setShowMsg(true)
        }        
    }

    const handleSecondSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try{
            const {token} = await authService.ownershipCheck(reqPayload)
            localStorage.setItem('token', token)
            window.location.href = "/dashboard"
        }catch(err){
            setMsgText(err.response.data.error)
            setShowMsg(true)
            setLoading(false)
        }        
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Sign up | scoremyrelic'
    }, [])

  return (
    <div className="root" style={{minHeight: '75vh', background: 'none', justifyContent: 'center'}}>
        <div className='app' style={{display: 'flex', flexDirection: viewportWidth < mobileSize ? 'column-reverse' : 'row', justifyContent: viewportWidth < mobileSize ? '' : 'center', alignItems: viewportWidth < mobileSize ? 'center' : '', gap: '32px',}}>
            
            <div className='main-container' style={{width: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px', boxSizing: 'border-box'}}>
                
                {currForm === 0 && 
                <>
                    <h1 style={{fontWeight: 400, textAlign: 'center', width: '100%', borderBottom: '1px solid #484239', paddingBottom: '16px', marginBottom: '0'}}>Sign Up</h1>
                    <form onSubmit={e => handleFirstSubmit(e)} className='auth-panel' style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        <label>New Username</label>
                        <input value={reqPayload.username} onChange={e => setReqPayload(old => {return ({...old, username: e.target.value})})}></input>
                        <label>Password</label>
                        <input type='password' value={reqPayload.password} onChange={e => setReqPayload(old => {return ({...old, password: e.target.value})})}></input>
                        <label>Confirm Password</label>
                        <input type='password' value={confPass} onChange={e => setConfPass(e.target.value)}></input>
                        <label>Hoyoverse UID</label>
                        <input value={reqPayload.uid} onChange={e => setReqPayload(old => {return ({...old, uid: e.target.value})})}></input>
                        <button className='default-btn' style={{marginRight: '0px', width: '150px', alignSelf: 'center'}}>Sign Up</button>
                    </form>
                </>}

                {currForm === 1 && <form onSubmit={loading ? () => {} : e => handleSecondSubmit(e)} className='auth-panel' style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center'}}>
                    <h2 style={{marginBottom: 0, fontWeight: 300}}>Your verification key:</h2>
                    <h2 style={{marginBottom: '4px', fontFamily: '"Roboto Mono", monospace', backgroundColor: '#00000077', padding:'8px 22px 8px 22px', borderRadius: '8px'}}>{authKeyDisplay}</h2>
                    <p style={{marginTop: 0, color: '#848484'}}>*The verification key will expire in 10 minutes.</p>
                    <h2 style={{marginBottom: 0, fontWeight: 300}}>Instructions:</h2>
                    <div className='signup-instructions' style={{marginTop: '8px', display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-start'}}>
                        <h3 style={{marginBottom: '4px'}}>Step 1. </h3>
                        <p style={{color: '#c4c4c4'}}>Log into your Honkai: Star Rail account.</p>
                        <h3 style={{marginBottom: '4px'}}>Step 2. </h3>
                        <p style={{color: '#c4c4c4'}}>Change your bio to EXACTLY match the verification key.</p>
                        <div style={{maxHeight: expand ? '800px' : '32px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', overflow: 'hidden', transition: `max-height 0.3s ease`}}>
                            <p style={{background: 'none', border: 'none', color: '#00A4EE', cursor: 'pointer'}} onClick={() => setExpand(old => !old)}>{expand ? 'Collapse instructions ▲' : 'Expand instructions ▼'}</p>
                            <img alt='' style={{width: '100%', marginBottom: '16px'}} src={require(`../assets/instructions/signup/step1.png`)}/>
                            <img alt='' style={{width: '100%'}} src={require(`../assets/instructions/signup/step2.png`)}/>
                        </div>
                        <h3 style={{marginBottom: '4px'}}>Step 3. </h3>
                        <p style={{color: '#c4c4c4'}}>Wait for Hoyoverse to update your bio (3+ mins).</p>
                        <p style={{color: '#c4c4c4'}}>(Optional) Log out of your Hoyoverse account to instantly change bio.</p>
                        <h3 style={{marginBottom: '4px'}}>Step 4. </h3>
                        <p style={{color: '#c4c4c4'}}>Click verify. If successful, it proves ownership of your account.</p>
                    </div>
                    <button className='default-btn' style={{marginRight: '0px', width: '130px', alignSelf: 'center', cursor: loading ? 'wait' : 'pointer'}}>Verify</button>
                </form>}

                {showMsg && <div style={{marginBottom: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', backgroundColor: '#d63a3a44', border: '1px solid #d63a3a', borderRadius: '8px', padding: '8px', boxSizing: 'border-box'}}>
                    <p style={{margin: 0}}>{msgText}</p>
                    {msgUid !== '' && <a target='_blank' rel="noreferrer" href={`https://scoremyrelic.com/search?=${msgUid}`}>Index it here.</a>}
                </div>}

                {currForm === 0 && <div style={{borderTop: '1px solid #484239', paddingTop: '16px', width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <Link style={{color: 'white', textDecoration: 'none'}} to="/login">Log in</Link>
                    {/* <Link style={{color: 'white', textDecoration: 'none'}} to="/reset-password">Forgot password?</Link> */}
                </div>}

                
            </div>

            <div style={{width: viewportWidth < mobileSize ? '95%' : '600px', maxWidth: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0px 16px 0px 16px', boxSizing: 'border-box', flexWrap: 'wrap'}}>
            <h2 style={{fontWeight: 400, textAlign: 'center', width: '100%', paddingBottom: '16px', marginBottom: '0'}}>Customize your profile with a free account</h2>
                <div style={{alignSelf: 'flex-start'}}>
                    <li>Custom profile pictures</li>
                    <li>Custom profile banners</li>
                    <li>Save character builds</li>
                    <li>Personalized profile URLs</li>
                    <li>Profile customization</li>
                    <img alt='' src={require('../assets/customprofile.png')} style={{width: '100%', marginTop: '8px', maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 75%, rgba(0,0,0,0))', WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 75%, rgba(0,0,0,0))'}}/>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default SignUp